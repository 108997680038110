<app-denunciante></app-denunciante>

<div id="pasos">
    <div id="headerPasos">
        <strong><i class="bi bi-house" style="font-size:2em"></i></strong>
        <p>Se muestran las actualizaciones de los estados de las denuncias ya registradas.</p>
    </div>
    <div class="animate__animated animate__backInLeft">
        <div id="bodyPasos" class="text-center" style=" color: rgb(27, 81, 113);">
            <h2>ID denuncia <br> <input required [(ngModel)]="codigo" type="text" size="15" id="inputID">
                <button type="submit" (click)="getDenuncia()"><i class="bi bi-forward-fill"></i></button>
            </h2>
            <div style="margin-top: 20px;"></div>
            <small *ngIf="captcha.invalid && captcha.touched" style="color: red;">Por favor, marque la siguiente casilla:</small><br>
            <!-- <re-captcha class="captcha" (resolved)="resolved($event)" required [(ngModel)]="captcha"></re-captcha> -->
            <re-captcha class="captcha" size="normal" (resolved)="resolved($event)" [formControl]="captcha"></re-captcha>
            <hr>
            <br>

            <!-- <div id="opcionesEstado">
                <input [value]="0" [(ngModel)]="tramitacion" type="radio" id="recibido" name="tramitacion" [checked]="isChecked" style="margin-left:10px">   <label for="recibido">Recibido</label><br>   <input [value]="1" [(ngModel)]="tramitacion" type="radio"
                    id="tramitando" name="tramitacion" [checked]="!isChecked">   <label for="tramitando">Tramitando</label><br>   <input [value]="2" [(ngModel)]="tramitacion" type="radio" id="resuelta" name="tramitacion" [checked]="!isChecked">   <label for="resuelta">Resuelta</label>
            </div> -->

            <div *ngIf="tramitacion == 0">
                <i class="bi bi-send-check"></i>
                <br>
                <p>Su denuncia ha sido recibida.</p>
                <br>
                <p>DENUNCIA REALIZADA HACE {{dias}} DÍAS</p>
                <small>El plazo máximo de resolución es de 3 meses</small>
            </div>
            <div *ngIf="tramitacion == 1">
                <i class="bi bi-hourglass-split"></i>
                <br>
                <p>Su denuncia se está tramitando en estos momentos.</p>
                <br>
                <p>DENUNCIA REALIZADA HACE {{dias}} DÍAS</p>
                <small>El plazo máximo de resolución es de 3 meses</small>
            </div>
            <div *ngIf="tramitacion == 2">
                <i class="bi bi-check2-all"></i>
                <br>
                <p>Su denuncia ya ha sido resulta.</p>
                <br>
                <p>ARCHIVOS DE RESOLUCIÓN:</p>
                <div *ngFor="let im of img; let j = index">
                    <a [href]="im" target="_blank" download="archivo">Archivo {{j+1}}</a>
                </div>
                <!-- <p>DENUNCIA REALIZADA HACE {{dias}} DÍAS</p>
                <small>El plazo máximo de resolución es de 3 meses</small> -->
            </div>
            <div *ngIf="tramitacion == 3">
                <i class="bi bi-exclamation-circle"></i>
                <br>
                <p>No existe una denuncia asociada al ID que ha escrito.</p>
            </div>
        </div>






    </div>
    <div style="height: 200px;"></div>