import { Component,  OnInit } from '@angular/core';
import { ServicioDenunciaService } from '../../../../services/servicio-denuncia.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-listado',
  templateUrl: './listado.component.html',
  styleUrls: ['./listado.component.css']
})
export class ListadoComponent implements OnInit {


  public denuncias;
  public denuncias_filtradas;
  public fecha_denuncia;
  public testigos;
  public implicados;
  public detalles;
  public fecha_detalle;
  public notas;
  public fecha_nota;
  public archivos;
  public img;
  public fechaArchivo;

  public archivosAnadidos = false;
  public archivosLoading = false;
  public cambiosRealizados = false;
  public notasAnadidas = false;
  public cambiosAnadidos = false;

  // Variables para los archivos a subir
  public archivo = [];
  public archivoASubir = [];
  public previsualizacion: Array<string> = [];

  public nota = new FormControl('');
  public categoria;
  public estado;
  public relaciones = [];
  public categorias = [];

  public orden = 1;
  public tipoFiltro = 0;
  public filtro = new FormControl('');
  public placeholderFiltro = 'Buscar por ID...';

  // Creamos boleano para mostrar o esconder contenido
  //Filtros iconos
  isShown: boolean = false; // hidden by default
  toggleShow() {
    this.isShown = !this.isShown;

  }

  //Tipo de filtro
  isShown2: boolean = false; // hidden by default
  toggleShow2() {
    this.isShown2 = !this.isShown2;

  }

  // Mostrar más detalles de la denuncia
  // show: boolean = false;
  show = [];
  masDetalles(i){
    this.show[i] = !this.show[i];
    if (this.archivosshow[i] == true || this.detallesshow[i] == true) {
      this.archivosshow[i] = false;
      this.detallesshow[i] = false;
    }
  }

  //Mostrar carpeta archivos
  // archivosshow: boolean = false;
  archivosshow = [];
  showArchivos(i, id = null) {
    this.archivosshow[i] = !this.archivosshow[i];
    
    if (id){
      this.getArchivos(id);
    }
  }

  detallesshow = [];
  aunMasDetalles(i, id = null){
    this.detallesshow[i] = !this.detallesshow[i];

    if (id){
      this.getDetallesDenuncia(id);
    }
  }


  constructor(private servicioDenuncia: ServicioDenunciaService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.getDenuncias();

    this.servicioDenuncia.getRelaciones().subscribe((relacion: any) => {
      this.relaciones = relacion;
      // console.log(this.relaciones);
    })
    this.servicioDenuncia.getCategoria().subscribe((categoria: any) => {
      this.categorias = categoria;
      // console.log(this.categorias);
    })
  }

  getDenuncias(filtro = 1) {

    this.denuncias = [];
    this.denuncias_filtradas = [];
    this.archivos = [];
    this.archivo = [];
    this.fecha_denuncia = [];
    this.categoria = [];
    this.estado = [];

    this.servicioDenuncia.getDenuncias().subscribe( res => {
      this.denuncias = res;
      this.denuncias_filtradas = res;

      if (filtro == 1) {
        this.denuncias.sort((a, b) => a.fecha_denuncia - b.fecha_denuncia);
      } else if (filtro == 2) {
        this.denuncias.sort((b, a) => a.fecha_denuncia - b.fecha_denuncia);
      }

      let contador = 0;
      for (let i = 0; i < this.denuncias.length; i++) {

        let fecha = new Date(parseInt(this.denuncias[i].fecha_denuncia)).toISOString();
        this.fecha_denuncia[contador] = fecha.slice(0,4) + '-' + fecha.slice(5,7) + '-' + fecha.slice(8,10);

        this.show[contador] = false;
        this.archivosshow[contador] = false;
        this.detallesshow[contador] = false;

        this.categoria[contador] = new FormControl(this.denuncias[i].categoria);
        this.estado[contador] = new FormControl(this.denuncias[i].estado);

        contador++;
      }

    });
  }

  filtrar() {

    if (this.tipoFiltro == 0) {
      this.placeholderFiltro = 'Buscar por ID...';
    } else {
      this.placeholderFiltro = 'Buscar por Categoría...';
    }

    let contador = 0;

    if (this.filtro.value.length > 0){

      this.denuncias_filtradas = [];

      for (const post of this.denuncias) {
        let categoria;
        if (this.tipoFiltro == 0){
          categoria = post.codigo_identificacion.toUpperCase();
        } else {
          categoria = post.categoria.toUpperCase();
        }
        const filtro = this.filtro.value.toUpperCase();

        if (categoria.indexOf(filtro) > -1) {
          this.denuncias_filtradas.push(post);
        }
      }

      if (this.orden == 1) {
        this.denuncias_filtradas.sort((a, b) => a.fecha_denuncia - b.fecha_denuncia);
      } else if (this.orden == 2) {
        this.denuncias_filtradas.sort((b, a) => a.fecha_denuncia - b.fecha_denuncia);
      }

      for (let i = 0; i < this.denuncias_filtradas.length; i++) {

        let fecha = new Date(parseInt(this.denuncias_filtradas[i].fecha_denuncia)).toISOString();
        this.fecha_denuncia[contador] = fecha.slice(0,4) + '-' + fecha.slice(5,7) + '-' + fecha.slice(8,10);

        this.show[contador] = false;
        this.archivosshow[contador] = false;
        this.detallesshow[contador] = false;

        this.categoria[contador] = new FormControl(this.denuncias_filtradas[i].categoria);
        this.estado[contador] = new FormControl(this.denuncias_filtradas[i].estado);

        contador++;
      }

    } else {

      this.denuncias_filtradas = this.denuncias;

      if (this.orden == 1) {
        this.denuncias_filtradas.sort((a, b) => a.fecha_denuncia - b.fecha_denuncia);
      } else if (this.orden == 2) {
        this.denuncias_filtradas.sort((b, a) => a.fecha_denuncia - b.fecha_denuncia);
      }

      for (let i = 0; i < this.denuncias_filtradas.length; i++) {

        let fecha = new Date(parseInt(this.denuncias_filtradas[i].fecha_denuncia)).toISOString();
        this.fecha_denuncia[contador] = fecha.slice(0,4) + '-' + fecha.slice(5,7) + '-' + fecha.slice(8,10);

        this.show[contador] = false;
        this.archivosshow[contador] = false;
        this.detallesshow[contador] = false;

        this.categoria[contador] = new FormControl(this.denuncias_filtradas[i].categoria);
        this.estado[contador] = new FormControl(this.denuncias_filtradas[i].estado);

        contador++;
      }

    }

  }

  getDetallesDenuncia(id) {

    this.testigos = [];
    this.implicados = [];
    this.detalles = [];
    this.notas = [];

    this.servicioDenuncia.getTestigo(id).subscribe( t => {
      this.testigos = t;

      this.servicioDenuncia.getImplicado(id).subscribe ( im => {
        this.implicados = im;

        this.servicioDenuncia.getDetalles(id).subscribe( de => {
          this.detalles = de;

          this.fecha_detalle = [];
          let contador = 0;
          for (let i = 0; i < this.detalles.length; i++) {
            this.fecha_detalle[contador] = new Date(+this.detalles[i].fecha_detalle).toLocaleDateString();
            contador++;
          }

          this.servicioDenuncia.getNotas(id).subscribe( no => {
            this.notas = no;

            this.fecha_nota = [];
            let contador2 = 0;
            for (let i = 0; i < this.notas.length; i++) {
              this.fecha_nota[contador2] = new Date(+this.notas[i].fecha_nota).toLocaleDateString();
              contador2++;
            }

          });
        });
      });
    });
  }

  getArchivos(id){

    this.img = [];
    this.fechaArchivo = [];

    this.servicioDenuncia.getArchivosById(id).subscribe( res => {

      this.archivos = res;
      let contador = 0;

      for (let i = 0; i < this.archivos.length; i++) {
        this.img[contador] = 'http://localhost/canalDenuncia2/uploads/' + this.archivos[i].archivo.slice(2, -2);
        // this.img[contador] = 'https://canaldenuncias.colegioveterinarios.net/canalDenuncia2/uploads/' + this.archivos[i].archivo.slice(2, -2);
        // this.img[contador] = 'https://canaldenuncias.quimsaitw.com/canalDenuncia/uploads/' + this.archivos[i].archivo.slice(2, -2);
        this.fechaArchivo[contador] = new Date(+this.archivos[i].fecha_archivo).toLocaleDateString();
        contador++;
      }

      // console.log(this.img);
      // console.log(this.fechaArchivo);

    });
  }

  fileChangeEvent(fileInput: any, id, codigo, resolucion = 0) {
    this.archivosLoading = true;
    this.archivoASubir = [];
    //contador para recorrer el array vacio y llenarlo (el array donde se guardan las imagenes)
    var contadorAyudaImagenes = 0;

    //rellenamos la variable con las imagenes que se acaban de enlazar
    for (let i = 0; i < fileInput.target.files.length; i++) {
      this.archivoASubir[i] = fileInput.target.files[i];
    }

    //recorremos este array y vamos leyendo imagen por imagen para ir previsualizandola
    this.archivoASubir.forEach(element => {
      this.extraerBase64(element).then((imagen: any) => {
        //guardamos la base de la imagen para previsualizarla
        this.previsualizacion[contadorAyudaImagenes] = imagen.base;
        //aumentamos el contador
        contadorAyudaImagenes++;
      });
    });

    // console.log(this.archivoASubir);
    // console.log(id);

    if ( resolucion == 0){
      this.subirArchivosAPI(id, codigo, resolucion);
    }


  }

  extraerBase64 = async ($event: any) => new Promise((resolve, reject) => {
    try {
      const unsafeImg = window.URL.createObjectURL($event);
      const image = this.sanitizer.bypassSecurityTrustUrl(unsafeImg);
      const reader = new FileReader();

      reader.readAsDataURL($event);
      reader.onload = () => {
        resolve({
          base: reader.result
        });
      };
      reader.onerror = error => {
        resolve({
          base: null
        });
      };

    } catch (e) {
      return null;
    }

  })


  subirArchivosAPI(id, codigo, resolucion) {
    this.servicioDenuncia.makeFileRequest(this.archivoASubir).subscribe(
      result => {
        //en caso de existir las subimos a la API y procedemos a guardar el pedido
        this.archivo = result;
        this.addArchivo(id, codigo, resolucion);
      }, error => {
        console.log(error);
      }
    );
  }

  addArchivo(id, codigo, resolucion){
    let contador = 0;
    let fecha = new Date().getTime();

    for (let i = 0; i < this.archivo.length; i++) {
      let json = JSON.stringify(this.archivo[i]);

      let archivodenuncias = {
        archivo: json,
        idDenuncia: id,
        fecha_archivo: fecha,
        resolucion: resolucion
      };

      this.servicioDenuncia.addArchivo(archivodenuncias).subscribe( result => {
        console.log('Archivos subidos');
        contador++;
        },
        error => {
          console.log(<any>error);
        }
      );
    }

    setTimeout(() => {

      if(resolucion == 0){
        if (contador > 0){
          let actualizacion = {
            idDenuncia: id,
            fecha_actualizacion: fecha,
            actualizacion: `Se ha(n) añadido ${contador} archivo(s) a la denuncia con id ${codigo}`,
            color: 'verde'
          };

          this.servicioDenuncia.addActualizacion(actualizacion).subscribe();
        }
      } else {
        if (contador > 0){
          let actualizacion = {
            idDenuncia: id,
            fecha_actualizacion: fecha,
            actualizacion: `Se ha añadido un archivo de resolución a la denuncia con id ${codigo}`,
            color: 'verde'
          };

          this.servicioDenuncia.addActualizacion(actualizacion).subscribe();
        }
      }

      this.archivoASubir = [];
      this.getDenuncias();
      this.archivosAnadidos = true;
      this.archivosLoading = false;
      window.scroll(0, 0);
    }, 2000);
  }

  addNota(id, codigo){

    let fecha = new Date().getTime();

    let notaASubir = {
      nota: this.nota.value,
      idDenuncia: id,
      fecha_nota: fecha
    };

    this.servicioDenuncia.addNota(notaASubir).subscribe( result => {
      console.log('Nota subida');

      let actualizacion = {
        idDenuncia: id,
        fecha_actualizacion: fecha,
        actualizacion: `Se ha añadido una nota a la denuncia con id ${codigo}`,
        color: 'verde'
      };

      this.servicioDenuncia.addActualizacion(actualizacion).subscribe();

      this.getDenuncias();
      this.notasAnadidas = true;
      window.scroll(0, 0);
    });
  }

  onSubmit(i, id, codigo) {

    let cambios = {
      id_denuncia: id,
      categoria: this.categoria[i].value,
      estado: this.estado[i].value
    };

    if (this.estado[i].value !== 'resuelta' || this.archivoASubir.length > 0){
      this.servicioDenuncia.updateDenunciaCE(cambios).subscribe( res => {
        console.log('Cambios subidos');

        let fecha = new Date().getTime();

        let actualizacion = {
          idDenuncia: id,
          fecha_actualizacion: fecha,
          actualizacion: `Se han realizado cambios a la denuncia con id ${codigo}`,
          color: 'verde'
        };

        this.servicioDenuncia.addActualizacion(actualizacion).subscribe();

        if (this.estado[i].value == 'resuelta'){
          let resolucion = 1;
          this.subirArchivosAPI(id, codigo, resolucion);
        }

        this.getDenuncias();
        this.cambiosAnadidos = true;
        window.scroll(0, 0);
      });
    } else {
      window.alert('Debe añadir un archivo de resolución');
    }




  }

}
