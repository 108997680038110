import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormArray, Validators, FormBuilder } from '@angular/forms';

import {NgbDate, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ServicioDenunciaService } from 'src/app/services/servicio-denuncia.service';

@Component({
  selector: 'app-uno',
  templateUrl: './uno.component.html',
  styleUrls: ['./uno.component.css']
})
export class UnoComponent implements OnInit  {

  // -------------------------------------------------------------------------------------------------
  // Variables

  /** Mostrar / Ocultar  */
  valueF: string;
  valueU:string;

  /* Fecha del calendario*/
  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate;
  toDate: NgbDate | null = null;

  public aceptado1 = new FormControl(false);
  public aceptado2 = new FormControl(false);

  // public aceptado1 = false;
  // public aceptado2 = false;

  public relaciones = [];
  public categorias = [];

  // Formulario de la denuncia
  public denuncia = new FormGroup ({
    fecha_denuncia: new FormControl(''),
    estado: new FormControl('recibida'),
    fecha_suceso: new FormControl(''),
    relacion: new FormControl(''),
    categoria: new FormControl(''),
    comentado: new FormControl(''),
    implicados: new FormControl(''),
    testigos: new FormControl(''),
    explicacion_suceso: new FormControl(''),
    anonima: new FormControl(true),
    nombre_denunciante: new FormControl(''),
    apellidos_denunciante: new FormControl(''),
    email_denunciante: new FormControl(''),
    telefono_denunciante: new FormControl(''),
    cargo_denunciante: new FormControl(''),
    provincia_denunciante: new FormControl(''),
    pais_denunciante: new FormControl(''),
    codigo_identificacion: new FormControl(''),
    clave_acceso: new FormControl(''),
  });

  // Array de formularios para implicados
  public implicadoFormArray = new FormArray([
    this.fb.group({
      idDenuncia: [''],
      nombre_implicado: [''],
      cargo_implicado: [''],
      hechos_acontecidos_implicado: [''],
      contacto_implicado: [''],
      domicilio_implicado: [''],
    })
  ]);

  // Array de formularios para testigos
  public testigoFormArray = new FormArray([
    this.fb.group({
      idDenuncia: [''],
      nombre_testigo: [''],
      cargo_testigo: [''],
      hechos_acontecidos_testigo: [''],
      contacto_testigo: [''],
      domicilio_testigo: [''],
    })
  ]);



  // ----------------------------------------------------------------------------------------------
  // Constructor y funciones

  constructor(calendar: NgbCalendar, private _router: Router, private fb: FormBuilder, private db: ServicioDenunciaService) {
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }



  // -------------------------------------------------------------------------------------------
  // Guardar

  onSubmit() {
    // console.log(this.denuncia.value);
    // console.log(this.denuncia.valid);

    if (this.denuncia.valid) {
      localStorage.setItem("denuncia", JSON.stringify(this.denuncia.value));
      this._router.navigate(["/dos"]);
    } else {
      window.alert('Por favor, introduzca los campos obligatorios');
    }
  }



  // --------------------------------------------------------------------------------------
  // Inicio

  ngOnInit(): void {
    // Recogemos los datos de anteriores formularios para la denuncia...
    if (JSON.parse(localStorage.getItem("denuncia"))) {

      this.denuncia.setValue(JSON.parse(localStorage.getItem("denuncia")));
      // console.log(this.denuncia.value);
    }

    // Para los implicados...
    let implicadosGuardados = JSON.parse(localStorage.getItem("implicado"));

    // console.log(implicadosGuardados);

    if (implicadosGuardados) {
      this.implicadoFormArray.removeAt(0);
      for (let i = 0; i < implicadosGuardados.length; i++) {

        let implicadoF = this.fb.group({
          idDenuncia: [implicadosGuardados[i].idDenuncia],
          nombre_implicado: [implicadosGuardados[i].nombre_implicado],
          cargo_implicado: [implicadosGuardados[i].cargo_implicado],
          hechos_acontecidos_implicado: [implicadosGuardados[i].hechos_acontecidos_implicado],
          contacto_implicado: [implicadosGuardados[i].contacto_implicado],
          domicilio_implicado: [implicadosGuardados[i].domicilio_implicado],
        });

        this.implicadoFormArray.push(implicadoF);
      }
    }
    // console.log(this.implicadoFormArray.value);


    // Y para los testigos
    let testigosGuardados = JSON.parse(localStorage.getItem("testigo"));

    // console.log(testigosGuardados);

    if (testigosGuardados) {
      this.testigoFormArray.removeAt(0);
      for (let i = 0; i < testigosGuardados.length; i++) {

        let testigoF = this.fb.group({
          idDenuncia: [testigosGuardados[i].idDenuncia],
          nombre_testigo: [testigosGuardados[i].nombre_testigo],
          cargo_testigo: [testigosGuardados[i].cargo_testigo],
          hechos_acontecidos_testigo: [testigosGuardados[i].hechos_acontecidos_testigo],
          contacto_testigo: [testigosGuardados[i].contacto_testigo],
          domicilio_testigo: [testigosGuardados[i].domicilio_testigo],
        });

        this.testigoFormArray.push(testigoF);
      }
    }
    // console.log(this.testigoFormArray.value);

    this.db.getRelaciones().subscribe((relacion: any) => {
      this.relaciones = relacion;
      // console.log(this.relaciones);
    })
    this.db.getCategoria().subscribe((categoria: any) => {
      this.categorias = categoria;
      // console.log(this.categorias);
    })

  }

}
