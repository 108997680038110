<div id="containerLogin1">

    <div id="izqLogin">
        <div class="text-center">
            <h2>Información sobre canales de denuncia externos</h2>
            <hr>
            <div class="text-left">
                <p>
                    Ballenoil, S.A. garantiza la difusión, clara y accesible, de información sobre los canales externos de información y sobre los procedimientos de denuncia externa ante las autoridades competentes y, en su caso, ante las instituciones, órganos u organismos
                    de la Unión Europea.
                </p>
                <p>
                    Las personas que, conforme al Reglamento del Sistema Interno de Información de Ballenoil, S.A. pueden tener la consideración de informantes, podrán informar de las infracciones incluidas en el ámbito material del referido SII, no sólo a través del Canal
                    de Denuncias que forma parte integrante de éste, sino también, de forma alternativa o complementaria, ante las autoridades competentes y, en su caso, ante las instituciones, órganos u organismos de la Unión Europea, a través de los
                    canales externos establecidos al efecto.
                </p>
            </div>
        </div>
    </div>
</div>





<div id="containerLogin2">
    <br>
    <div class="text-left">
        <strong>
            A. Canales externos habilitados por la Unión Europea:
        </strong>
        <p>
            Los informantes disponen de los siguientes canales externos para la comunicación de infracciones de las normas e intereses de la Unión Europea:
        </p>
        <u>
            1. Oficina Europea de Lucha contra el Fraude (OLAF):
        </u>
        <p>
            La OLAF dispone de un canal externo para la denuncia de fraudes al presupuesto de la Unión Europea u otras irregularidades graves con posibles repercusiones negativas para los intereses económicos de la UE (ingresos, gastos o activos de las instituciones
            de la UE), así como faltas graves de los miembros o el personal de la instituciones y organismos de la UE.
        </p>
        <p>
            Las denuncias pueden formularse de forma anónima, en cualquiera de las 24 lenguas oficiales del UE, a través de los siguientes medios:
        </p>
        <ul>
            <li>
                En línea, a través del Sistema de notificación de fraudes: <br> <a href="https://fns.olaf.europa.eu/main_es.htm">https://fns.olaf.europa.eu/main_es.htm</a>
            </li>
            <li>
                Por correo postal: <br> European Commission <br> European Anti-Fraud Office (OLAF) <br> 1049 Brussels <br> Bélgica <br>
            </li>
        </ul>
        <u>
            2. Fiscalía Europea (EPPO):
        </u>
        <p>
            La Fiscalía Europea es un órgano independiente de la Unión Europea encargado de investigar los delitos que atenten contra los intereses financieros de la UE y de ejercer la acción penal contra sus autores y llevarlos a juicio, en particular en lo que
            respecta al fraude, la corrupción, el blanqueo de dinero, y el fraude transfronterizo en materia de IVA.
        </p>
        <p>
            Las denuncias pueden formularse en línea, a través del servicio «Report a Crime»: <br> <a href="https://www.eppo.europa.eu/en/reporting-crime-eppo">https://www.eppo.europa.eu/en/reporting-crime-eppo</a>
        </p>
        <p>
            La Fiscalía Europea no recibe denuncias anónimas, por lo que la comunicación de infracciones a través de este canal requiere la identificación del informante.
        </p>

        <strong>
            B. Canales externos habilitados a nivel nacional:
        </strong>
        <p>
            A nivel nacional, los informantes dispondrán del canal externo que se habilite al efecto por la Autoridad Independiente de Protección al Informante, A.A.I. En la actualidad, tanto dicha entidad como el canal están pendientes de constitución.
        </p>
        <p>
            No obstante, en la actualidad existen, a nivel nacional, los siguientes canales:
        </p>
        <u>
            1. Servicio Nacional de Coordinación Antifraude (SNCA)
        </u>
        <p>
            El SNCA es el órgano encargado de coordinar las acciones encaminadas a proteger los intereses financieros de la Unión Europea contra el fraude, en colaboración con la Oficina Europea de Lucha contra el Fraude (OLAF). A través del canal de denuncias habilitado,
            pueden reportarse informaciones sobre fraudes o irregularidades que afecten a fondos europeos.
        </p>
        <p>
            Las denuncias pueden formularse en línea, a través del servicio Infofraude: <br> <a href="https://www.igae.pap.hacienda.gob.es/sitios/igae/es-ES/snca/Paginas/ComunicacionSNCA.aspx">https://www.igae.pap.hacienda.gob.es/sitios/igae/es-ES/snca/Paginas/ComunicacionSNCA.aspx</a>            <br> <a href="https://www.igae.pap.hacienda.gob.es/sitios/igae/es-ES/Paginas/denan.aspx">https://www.igae.pap.hacienda.gob.es/sitios/igae/es-ES/Paginas/denan.aspx</a>
        </p>
        <p>
            El formulario del SNCA no permite denuncias anónimas, por lo que la comunicación de infracciones a través de este canal requiere la identificación previa del informante.
        </p>
        <p>
            Con carácter previo a la eventual remisión de información puede plantearse a dicho Servicio, a través de la siguiente dirección de correo electrónico (<a href="mailto:consultasantifraude@igae.hacienda.gob.es">consultasantifraude@igae.hacienda.gob.es</a>),
            las cuestiones que se estimen oportunas en relación con la forma y requisitos con los que la información debe ser remitida, el tratamiento que se dará a la misma, y, en general, cualquier aspecto relativo a la remisión de información a través
            de este canal de comunicación.
        </p>
        <u>
            2. Buzón antifraude - Canal de denuncias del Mecanismo para la Recuperación y Resiliencia
        </u>
        <p>
            Gestionado igualmente por el Servicio Nacional de Coordinación Antifraude, esta herramienta trata de dar respuesta a un aspecto esencial en la ejecución de las medidas incorporadas en el Plan de Recuperación, Transformación y Resiliencia como es el relativo
            a la protección de los intereses financieros de la Unión Europea frente a los cuatro riesgos específicos que se asocian a esta ejecución: el fraude, la corrupción, los conflictos de interés y la doble financiación.
        </p>
        <p>
            Las denuncias pueden formularse en línea, a través del servicio Infofraude: <br> <a href="https://www.igae.pap.hacienda.gob.es/sitios/igae/es-ES/Paginas/denan.aspx">https://www.igae.pap.hacienda.gob.es/sitios/igae/es-ES/Paginas/denan.aspx</a>
        </p>
        <strong>
            C. Canales externos habilitados a nivel autonómico
        </strong>
        <p>
            Para la comunicación de incumplimientos que se circunscriban a su ámbito territorial y que no sean competencia de la Autoridad Independiente de Protección al Informante, algunas comunidades autónomas han habilitado canales externos de información propios.
        </p>
        <p>
            En caso del Principado de Asturias actualmente se encuentra activo el siguiente canal:
        </p>
        <u>
            1. Canal de lucha contra la corrupción del Principado de Asturias
        </u>
        <p>
            Al objeto de atender posibles denuncias relativas a la gestión de los fondos europeos se habilita una dirección de correo electrónico en la Inspección General de Servicios del Principado de Asturias, abierto al conjunto de agentes y ciudadanía en general.
        </p>
        <p>
            Las comunicaciones recibidas en este buzón electrónico dependiendo de su tipología (consulta, queja, reclamación administrativa…) serán trasladadas a la instancia competente para su posterior tratamiento.
        </p>
        <p>
            El buzón habilitado al efecto es: <a href="mailto:INFOFRAUDEASTURIAS@asturias.org">INFOFRAUDEASTURIAS@asturias.org</a>
        </p>

    </div>
    <div style="height: 150px;"></div>
</div>