<div id="emojiDiv pos">
    <a routerLink="/index"><i class="bi bi-arrow-bar-left pos" id="emoji" style="color:rgba(19, 81, 117, 0.603) !important; "></i></a>
</div>
<!-- <div style="height: 60px; margin-top:-5px"></div> -->


<div class="text-center" id="confirm" role="alert">
    <div>
        ¡ DENUNCIA CONFIRMADA !
    </div>
    <br>
    <img height="100px" src="https://funermostra.feriavalencia.com/wp-content/uploads/2019/03/604a0cadf94914c7ee6c6e552e9b4487-icono-de-c-rculo-de-marca-de-verificaci-n-curvo-by-vexels-300x300.png">
</div>



<div id="textoConfirmacion" class="text-center">
    <!-- <h6>Para comprobar el estado de la nueva denuncia, registre el ID de la nueva denuncia.</h6> -->
    <h5>El ID de su denuncia es: <strong>{{codigo}}</strong></h5>
    <h6 style="color: red;">Consérvelo para poder comprobar su estado</h6>
    <br>
    <h3 class="animate__animated animate__bounce"><i class="bi bi-chevron-double-down" style="color:green"></i></h3>
</div>

<div id="datosConfirmacion" class="text-center">
    <button type="button" class="btn btn-success" (click)="captureScreen()">Descargar PDF</button>


    <br><br>
    <button routerLink="/denunciante" type="button" id="botonConfirmacion" class="btn btn-outline-success">Comprueba el estado</button>
</div>
<br><br><br>


<!-- ----------------------------------------GENERACIÓN DE PDF OCULTA EN EL HTML------------------------------- -->

<!-- El id pdf está oculto en el scss, mientras que el id pdfTable es el que lee html2canvas -->
<div id="pdf" #pdf>
    <div id="pdfTable" #pdfTable>

        <!-- Inicio PDF denuncia -->
        <div class="container" *ngIf="denuncia">
            <div class="row">
                <div class="col-12 mt-5">
                    <br><br>
                    <hr class="hrpdf">
                    <h1 class="text-center"><strong class="letrapdf">Denuncia ID: {{denuncia.codigo_identificacion}}</strong></h1>
                    <hr class="hrpdf">
                </div>
            </div>

            <div class="row justify-content-between">
                <div class="col-4 mr-5 mt-5 ml-5">

                    <h4 class="letrapdf">Fecha de la denuncia:</h4>

                </div>
                <div class="col-5 mr-5 mt-5 ml-5">
                    <h5 class="letrapdf">{{fecha_denuncia}}</h5>
                </div>
            </div>
            <hr>

            <div class="row justify-content-between">
                <div class="col-4 mr-5 ml-5">

                    <!-- <h4 class="letrapdf">Relación con el Colegio:</h4> -->
                    <h4 class="letrapdf">Relación con la empresa:</h4>

                </div>
                <div class="col-5 mr-5 ml-5">
                    <h5 class="letrapdf">{{denuncia.relacion}}</h5>
                </div>
            </div>
            <hr>

            <div class="row justify-content-between">
                <div class="col-4 mr-5 ml-5">

                    <h4 class="letrapdf">Categoría de la denuncia:</h4>

                </div>
                <div class="col-5 mr-5 ml-5">
                    <h5 class="letrapdf">{{denuncia.categoria}}</h5>
                </div>
            </div>
            <hr>

            <div class="row justify-content-between">
                <div class="col-4 mr-5 ml-5">

                    <h4 class="letrapdf">Fecha del suceso</h4>

                </div>
                <div class="col-5 mr-5 ml-5">
                    <h5 class="letrapdf" *ngIf="denuncia.fecha_suceso">{{denuncia.fecha_suceso}}</h5>
                    <h5 class="letrapdf" *ngIf="!denuncia.fecha_suceso">No indicada</h5>
                </div>
            </div>
            <hr>

            <div class="row justify-content-between">
                <div class="col-4 mr-5 ml-5">

                    <h4 class="letrapdf">Comentado con dirección</h4>

                </div>
                <div class="col-5 mr-5 ml-5">
                    <h5 class="letrapdf">{{denuncia.comentado}}</h5>
                </div>
            </div>
            <hr>

            <div class="row justify-content-between">
                <div class="col-4 mr-5 ml-5">

                    <h4 class="letrapdf">Implicado</h4>

                </div>
                <div class="col-5 mr-5 ml-5">
                    <h5 class="letrapdf">{{denuncia.implicados}}</h5>
                </div>
            </div>
            <hr>

            <div class="row justify-content-between">
                <div class="col-12 mr-5 ml-5">

                    <h4 class="letrapdf">Datos de los Implicados:</h4>

                </div>
                <div class="col-12 mr-5 ml-5">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Cargo</th>
                                <th>Hechos Acontecidos</th>
                                <th>Teléfono/Email</th>
                                <th>Domicilio</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let implicado of implicados">
                                <td>{{implicado.nombre_implicado}}</td>
                                <td>{{implicado.cargo_implicado}}</td>
                                <td>{{implicado.hechos_acontecidos_implicado}}</td>
                                <td>{{implicado.contacto_implicado}}</td>
                                <td>{{implicado.domicilio_implicado}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <hr>

            <div class="row justify-content-between" *ngIf="testigos">
                <div class="col-12 mr-5 ml-5">

                    <h4 class="letrapdf">Datos de los Testigos:</h4>

                </div>
                <div class="col-12 mr-5 ml-5" *ngIf="testigos.length > 0">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Cargo</th>
                                <th>Hechos Acontecidos</th>
                                <th>Teléfono/Email</th>
                                <th>Domicilio</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let testigo of testigos">
                                <td>{{testigo.nombre_testigo}}</td>
                                <td>{{testigo.cargo_testigo}}</td>
                                <td>{{testigo.hechos_acontecidos_testigo}}</td>
                                <td>{{testigo.contacto_testigo}}</td>
                                <td>{{testigo.domicilio_testigo}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-12 mr-5 ml-5" *ngIf="testigos.length == 0">
                    <h5 class="letrapdf">Sin testigos añadidos</h5>
                </div>
            </div>
            <hr>

            <div class="row justify-content-between">
                <div class="col-12 mr-5 ml-5">

                    <h4 class="letrapdf">Descripción del suceso</h4>

                </div>
                <div class="col-12 mr-5 ml-5">
                    <p class="letrapdf">{{denuncia.explicacion_suceso}}</p>
                </div>
            </div>

        </div>








    </div>
</div>

<div class="bottommobile" style="height: 200px;"></div>