import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CuatroComponent } from './pages/pasos/cuatro/cuatro.component';
import { DosComponent } from './pages/pasos/dos/dos.component';
import { TresComponent } from './pages/pasos/tres/tres.component';
import { UnoComponent } from './pages/pasos/uno/uno.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSliderModule } from '@angular/material/slider';
import { PasosComponent } from './pages/pasos/pasos.component';
import { LoginComponent } from './pages/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UsuarioModel } from './models/usuario.model/usuario.model';
import { DenunciaModel } from './models/denuncia.model/denuncia.model';
import { ConfirmacionComponent } from './pages/pasos/confirmacion/confirmacion.component';
import { InicioDenuncianteComponent } from './pages/home/denunciante/inicio/inicio.component';
import { ListadoComponent } from './pages/home/empresa/listado/listado.component';
import { InicioComponent } from './pages/home/empresa/inicio/inicio.component';
import { EmpresaComponent } from './pages/home/empresa/empresa.component';
import { DenuncianteComponent } from './pages/home/denunciante/denunciante.component';
import { ExtrasComponent } from './pages/home/denunciante/extras/extras.component';
import { IndexComponent } from './pages/index/index.component';
import { ServicioDenunciaService } from './services/servicio-denuncia.service';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { ProteccionDatosComponent } from './pages/proteccion-datos/proteccion-datos.component';
import { TerminosUsoComponent } from './pages/terminos-uso/terminos-uso.component';
import { CanalesExternosComponent } from './pages/canales-externos/canales-externos.component';
import { ToastrModule } from 'ngx-toastr';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';





@NgModule({
  declarations: [
    AppComponent,
    UnoComponent,
    DosComponent,
    TresComponent,
    CuatroComponent,
    PasosComponent,
    LoginComponent,
    ConfirmacionComponent,
    ListadoComponent,
    InicioDenuncianteComponent,
    InicioComponent,
    EmpresaComponent,
    DenuncianteComponent,
    ExtrasComponent,
    IndexComponent,
    ProteccionDatosComponent,
    TerminosUsoComponent,
    CanalesExternosComponent,
  
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatSliderModule, //Estilos
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    MatProgressSpinnerModule,
    ToastrModule.forRoot(),
    RecaptchaModule,
    RecaptchaFormsModule
  ],
  providers: [
    ServicioDenunciaService,
    UsuarioModel,
    DenunciaModel,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptcha.siteKey,
      } as RecaptchaSettings,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
