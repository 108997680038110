<app-pasos></app-pasos>


<div id="pasos">

    <div id="headerPasos">
        <strong>Información personal</strong>

        <p>Si prefiere no indicar sus datos personales, puede hacerlo indicando que quiere que la denuncia sea anónima.
        </p>
    </div>
    <div id="bodyPasos">
        <form autocomplete="off" [formGroup]="denuncia" (ngSubmit)="onSubmit()">
            <div id="divAnonimo">
                <p id="asterisco">¿Desea que la denuncia sea anónima? </p>
                <span class="swtich-container">
                    <!-- <input type="checkbox" id="switch" name="myCheckboxF" (click)="showForm()"
                       required> -->
                    <input type="checkbox" id="switch" name="myCheckboxF" formControlName="anonima">
                    <label for="switch" class="lbl"></label>
                </span>
            </div>

            <hr>

            <!-- <div *ngIf="anonimo" id="formulario" class="hide"> -->
            <div *ngIf="!denuncia.get('anonima').value" id="formulario" class="hide">
                <div class="formulario">
                    <div class="input-group mb-3">
                        <span class="input-group-text titulo">Nombre y apellidos</span>
                        <input formControlName="nombre_denunciante" type="text" aria-label="First name" class="form-control intro" aria-describedby="basic-addon1">
                        <input formControlName="apellidos_denunciante" type="text" aria-label="Last name" class="form-control intro" aria-describedby="basic-addon1">
                    </div>
                    <div class="input-group mb-3">
                        <span class="input-group-text titulo" id="basic-addon1">Email y teléfono</span>
                        <input formControlName="email_denunciante" type="email" class="form-control" aria-label="Email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" aria-describedby="basic-addon1">
                        <input formControlName="telefono_denunciante" type="text" class="form-control" aria-label="Teléfono" aria-describedby="basic-addon1">
                    </div>

                    <div class="input-group mb-3">
                        <span class="input-group-text titulo" id="basic-addon1">Cargo</span>
                        <input formControlName="cargo_denunciante" type="text" class="form-control" aria-label="Teléfono" aria-describedby="basic-addon1">
                    </div>

                    <div class="input-group mb-3">
                        <span class="input-group-text titulo">Provincia y país</span>
                        <input formControlName="provincia_denunciante" type="text" aria-label="Provincia" class="form-control">
                        <input formControlName="pais_denunciante" type="text" aria-label="País" class="form-control">
                    </div>
                </div>
            </div>
            <div id="bottom2">
                <p>Este canal es confidencial. Sus datos personales no serán compartidos. Si alguien de la organización necesita conocer sus datos, lo solicitará a través del canal y solo si ud. lo autoriza los podrá ver.</p>
            </div>
            <input type="checkbox" [formControl]="aceptado" required><span id="asterisco"> He leído el <a class="text-muted" target="_blank" routerLink="/proteccion-datos">Aviso sobre
                protección
                de datos y
                consentimiento</a> y acepto el procesamiento de mis datos personales de conformidad con lo dispuesto
            en
            dicho aviso.</span>

            <br><br>
            <button class="btn btn-primary" (click)="anterior()">Anterior</button>
            <!-- <button class="btn btn-primary" routerLink="/dos">Anterior</button> -->


            <button [disabled]="!aceptado.value" type="submit" class="btn btn-primary" id="buttonDcha">
                Confirmar Denuncia
            </button>
            <div *ngIf="loading" style="float: right;">
                <mat-spinner diameter="35"></mat-spinner>
            </div>
        </form>
    </div>
</div>
<div class="bottommobile" style="height: 200px;"></div>

<br>