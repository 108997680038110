<div id="containerLogin1">

    <!-- <button (click)="prueba()">prueba</button> -->

    <div id="izqLogin">
        <div class="text-center">
            <!-- <h2>Bienvenido al canal de denuncias del Colegio Oficial de Veterinarios del Principado de Asturias</h2> -->
            <h2>Bienvenido al canal de denuncias</h2>
            <hr>
            <div class="text-left">
                <!-- <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Harum dolor similique quo repellat optio neque architecto nihil vero suscipit, aperiam exercitationem eos recusandae nulla inventore sapiente officiis rem natus veritatis?</p> -->
                <p>
                    Se trata de un canal de comunicación entre empleados, clientes y proveedores, entre otros, con el fin de alertar confidencialmente a una organización sobre sospechas de mala conducta. Ofrecemos una plataforma de gestión, con el objetivo de denunciar (de
                    manera anónima si así se desea) acerca irregularidades o incumplimientos de la normativa cometidos tanto por la propia organización como por terceros que mantengan relación con dicha organización.

                </p>
                <!-- <p>
                    Se trata de un canal de comunicación entre empleados, colegiados, clientes y proveedores, entre otros, con el fin de alertar confidencialmente a una organización sobre sospechas de mala conducta. Ofrecemos una plataforma de gestión, con el objetivo de
                    denunciar (de manera anónima si así se dese) acerca irregularidades o incumplimientos de la normativa cometidos tanto por la propia organización como por terceros que mantengan relación con dicha organización. Aquellas acciones u omisiones
                    de los colegiados y de las personas que ocupen cargos directivos en el Colegio Oficial que puedan constituir una infracción de los deberes deontológicos, de acuerdo con el Código Deontológico para el ejercicio de la profesión veterinaria
                    del Consejo General de Colegios Veterinarios, en cuanto no constituyan infracción penal o administrativa grave o muy grave, quedan excluidas del Canal de Denuncias, debiendo comunicarse a través del sistema de Ventanilla Única para
                    particulares accesible desde la página web corporativa del Colegio Oficial de Veterinarios del Principado de Asturias. </p> -->
                <!--    <p>El denunciante realiza la denuncia a través de un formulario explicando la situación detalladamente
                        por
                        la cual se hace la denuncia.
                        Una vez recibida dicha denuncia, será estudiada y desde ese momento, procederá a una investigación
                        interna.
                        Los resultados serán expuestos en un plazo de 3 meses como máximo.
                    </p>
                    -->
            </div>
        </div>
        <br>
        <div id="lista">
            <h3 style="padding-left:25px">Requisitos</h3>
            <ul>
                <li>Exposición detallada y clara acerca de los hechos.</li>
                <li>Lugar o momento de dicho suceso.</li>
                <li>Identificación de las personas involucradas/testigos.</li>
                <li>Aportar, si se considera necesario, documentos, archivos u otra información la cual sea relevante para dicha denuncia.</li>
            </ul>
        </div>

    </div>


    <div id="dchaLogin">

        <div class="text-center">
            <h4>¿Eres denunciante?</h4>
        </div>

        <button type="button" id="botonLogin" class="animate__animated animate__heartBeat" routerLink="/index">
            Acceder
        </button>
        <br><br><br>

        <div class="text-center">
            <!-- <h4>Inicio de sesión para el Colegio:</h4> -->
            <h4>Inicio de sesión para la empresa:</h4>
        </div>

        <form class="login-form" [formGroup]="login">

            <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1"><i class="bi bi-person-circle"></i></span>
                <input formControlName="user" type="text" class="col-xs-2">
            </div>

            <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1"><i class="bi bi-key-fill"></i></span>
                <input formControlName="password" type="password" class="col-xs-2">
            </div>
        </form>

        <small class="incorrecto" *ngIf="erroneo">Usuario o contraseña incorrectos</small>

        <!--    SOLO PARA EMPRESAS -->
        <!-- <a id="olvidar">¿Has olvidado tu contraseña?</a> -->


        <!-- <br><br> -->


        <button type="button" id="botonLogin" class="animate__animated animate__heartBeat" (click)="doLogin()">
            Iniciar Sesión
            <div *ngIf="loading" style="float: left;">
                <mat-spinner diameter="25"></mat-spinner>
            </div>
			</button>
    </div>
</div>





<div id="containerLogin2">
    <br>
    <div class="text-center">
        <h2>¿Cuál es el proceso de la denuncia?</h2>
        <br>
        <table class="table">
            <tr>
                <th>
                    <ul>
                        <li><i class="bi bi-chat-left-text"></i></li>
                        <!-- <li>Colegio</li> -->
                        <li>Empresa</li>
                        <li><small>Comunica la existencia del canal de denuncias</small></li>
                    </ul>
                </th>
                <th>
                    <ul>
                        <li><i class="bi bi-file-earmark-plus"></i></li>
                        <li>Informante</li>
                        <li><small>Registra una denuncia</small></li>
                    </ul>
                </th>
                <th>
                    <ul>
                        <li><i class="bi bi-share"></i></li>
                        <li>Canal de Denuncias</li>
                        <li><small>Comunica dicha denuncia</small></li>
                    </ul>
                </th>
                <th>
                    <ul>
                        <li><i class="bi bi-envelope-exclamation"></i></li>
                        <li>Informante</li>
                        <li><small>Recibe información acerca la tramitación</small></li>
                    </ul>
                </th>
                <th>
                    <ul>
                        <li><i class="bi bi-check2-circle"></i></li>
                        <!-- <li>Colegio</li> -->
                        <li>Empresa</li>
                        <li><small>Denuncia confirmada. Eliminación de los datos dentro del plazo legal</small></li>
                    </ul>
                </th>
            </tr>
        </table>
    </div>
    <div style="height: 75px;"></div>
</div>
<div id="containerLogin3">
    <br>
    <div class="text-center">
        <h2>¿Cuál es el proceso de la denuncia?</h2>
        <br>
        <table class="table">
            <tr>
                <th>
                    <ul>
                        <li><i class="bi bi-chat-left-text"></i></li>
                        <!-- <li>Colegio</li> -->
                        <li>Empresa</li>
                        <li><small>Comunica la existencia del canal de denuncias</small></li>
                    </ul>
                </th>
                <th>
                    <ul>
                        <li><i class="bi bi-file-earmark-plus"></i></li>
                        <li>Informante</li>
                        <li><small>Registra una denuncia</small></li>
                    </ul>
                </th>
            </tr>
            <tr>
                <th>
                    <ul>
                        <li><i class="bi bi-share"></i></li>
                        <li>Canal de Denuncias</li>
                        <li><small>Comunica dicha denuncia</small></li>
                    </ul>
                </th>
                <th>
                    <ul>
                        <li><i class="bi bi-envelope-exclamation"></i></li>
                        <li>Informante</li>
                        <li><small>Recibe información acerca la tramitación</small></li>
                    </ul>
                </th>
            </tr>
            <tr>
                <th>
                    <ul>
                        <li><i class="bi bi-check2-circle"></i></li>
                        <!-- <li>Colegio</li> -->
                        <li>Empresa</li>
                        <li><small>Denuncia confirmada. Eliminación de los datos dentro del plazo legal</small></li>
                    </ul>
                </th>
                <th>
                    <ul>
                        <li></li>
                        <li><small></small></li>
                    </ul>
                </th>
            </tr>
        </table>
    </div>
    <div style="height: 75px;"></div>
</div>