import { Component } from '@angular/core';
import { Router } from '@angular/router';
import {MDCTextField} from '@material/textfield';
import { ToastrService } from 'ngx-toastr';
const MINUTES_UNITL_AUTO_LOGOUT = 30 // in mins
const CHECK_INTERVAL = 15000 // in ms
const STORE_KEY =  'lastAction';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'CanalDenuncias';

  public getLastAction() {
    return parseInt(localStorage.getItem(STORE_KEY));
  }
 public setLastAction(lastAction: number) {
    localStorage.setItem(STORE_KEY, lastAction.toString());
  }

  constructor(private router: Router, private toast: ToastrService) {
    this.check();
    this.initListener();
    this.initInterval();
    localStorage.setItem(STORE_KEY,Date.now().toString());
  }

  initListener() {
    document.body.addEventListener('click', () => this.reset());
    document.body.addEventListener('mouseover',()=> this.reset());
    document.body.addEventListener('mouseout',() => this.reset());
    document.body.addEventListener('keydown',() => this.reset());
    document.body.addEventListener('keyup',() => this.reset());
    document.body.addEventListener('keypress',() => this.reset());
  }

  reset() {
    this.setLastAction(Date.now());
  }

  initInterval() {
    setInterval(() => {
      this.check();
    }, CHECK_INTERVAL);
  }

  check() {
    const now = Date.now();
    const timeleft = this.getLastAction() + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
    // const timeleft = this.getLastAction() + MINUTES_UNITL_AUTO_LOGOUT;
    const diff = timeleft - now;
    const isTimeout = diff < 0;

    if (isTimeout && localStorage.getItem('userTokenDenuncias'))  {
      localStorage.clear();
      this.router.navigate(['./login']);
      this.toast.warning('Sesión cerrada por inactividad', '', { "positionClass": "toast-top-right" });
    }
  }

}
