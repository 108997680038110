<app-empresa></app-empresa>

<div id="pasos">
    <div id="headerPasos">
        <strong><i class="bi bi-house" style="font-size:2em"></i></strong>
        <p>Se muestran las actualizaciones de los estados de las denuncias ya registradas.</p>
    </div>
    <div id="bodyPasos" class="text-center">

        <div id="contenidoInicio" *ngFor="let actualizacion of actualizaciones_recientes; let i = index">
            <div [ngStyle]="{'background-color': colores[i]}" class="alert" role="alert">
                <span class="titulo">{{actualizacion.actualizacion}}</span>
                <small class="fecha" style="float:right">{{fechas[i]}}</small>

            </div>

        </div>

        <!-- <div id="contenidoInicio">
            <div class="alert alert-success" role="alert">
                La denuncia '0001' ha sido resulta
                <small style="float:right">[21-11-2121]</small>
            </div>
            <div class="alert alert-warning" role="alert">
                La denuncia '0001' está en tramitación
                <small style="float:right">[21-11-2121]</small>
            </div>
            <div class="alert alert-danger" role="alert">
                La denuncia '0001' ha sido modificada
                <small style="float:right">[21-11-2121]</small>
            </div>
            <div class="alert alert-info" role="alert">
                La denuncia '0001' ha sido enviada
                <small style="float:right">[21-11-2121]</small>
            </div>
        </div> -->

    </div>
</div>

<div class="bottommobile" style="height: 200px;"></div>