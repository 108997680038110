<div id="containerLogin1">

    <div id="izqLogin">
        <div class="text-center">
            <h2>
                Términos de uso del Canal de Denuncias de BALLENOIL, S.A.
            </h2>
            <hr>
            <div class="text-left">
                <p>
                    En cumplimiento de la Ley de Servicios de la Sociedad de la Información y Comercio electrónico, así como en cumplimiento de la normativa vigente en materia de protección de datos, se informa que el titular de la web https://www.ballenoil.es/ (en adelante,
                    “la web”) y del Canal de Denuncias accesible a través de la misma (en adelante, “el Canal”) es Ballenoil, S.A., con CIF A-65371171, y domicilio social en Calle Valgrande, 30, C.P. 28108, Alcobendas, Madrid, España (en adelante, “Ballenoil”).
                </p>
                <p>
                    El simple acceso, navegación y uso del Canal de Denuncias atribuye la condición de usuario del mismo (en adelante, el “usuario”) e implica la aceptación de los presentes términos y condiciones. La aceptación del presente documento conlleva que el usuario:
                </p>
                <ul>
                    <li>ha leído, entiende y comprende lo aquí expuesto</li>
                    <li>asume todas las obligaciones aquí expuestas.</li>
                </ul>
                <p>
                    Los presentes Términos de uso tendrán un período de validez indefinido. Ballenoil se reserva el derecho a modificarlos y/o actualizarlos en cualquier momento sin previo aviso, por lo que se recomienda revisarlas frecuentemente.
                </p>
            </div>
        </div>
    </div>
</div>





<div id="containerLogin2">
    <br>
    <div class="text-left">
        <strong>Condiciones para el usuario</strong>
        <br><br>
        <ul>
            <li>
                Como usuario del Canal de Denuncias de Ballenoil, S.A. te obligas a hacer un uso correcto y lícito de la plataforma.
            </li>
            <li>
                Utilizarás la plataforma para los objetivos que ha sido diseñada, y en ningún caso la utilizarás con objetivos contrarios a la ley.
            </li>
            <li>
                El Canal de Denuncias no es un servicio de emergencia. En caso de emergencia ponte en contacto con las autoridades competentes.
            </li>
            <li>
                Deberás cumplimentar toda la información definida como obligatoria, a través de un asterisco, para poder proceder al envío de la comunicación. Entre dicha información no se encuentra la identidad del informante, pudiendo presentarse la comunicación de
                forma anónima.
            </li>
            <li>
                Este servicio no está diseñado para presentar información que pueda considerarse secreta o estrictamente confidencial.
            </li>
            <li>
                Reflexiona y revisa la información que envías para asegurar, que si no lo deseas, no revela tu identidad.
            </li>
            <li>
                Podrás elegir entre enviar la comunicación indicando tus datos de contacto o sin indicarlos. En ambos casos la plataforma te proporcionará un número de identificación o referencia única; es importante que conserves esta identificación, solo con ella podrás
                consultar el estado de la comunicación. Si pierdes este identificador no podrás volver a acceder a la información sobre la comunicación presentada. Por motivos de seguridad y confidencialidad, Ballenoil no podrá proporcionar acceso a códigos
                perdidos.
            </li>
            <li>
                Si Ballenoil comprueba que ha habido mala fe al realizar la denuncia, se reserva el derecho a tomar acciones disciplinarias y/o legales si el denunciante ha sido identificado.
            </li>
            <li>
                Podrás descargarte un certificado en formato PDF en el que figurará el número de identificador de tu comunicación, así como toda la información que has enviado en la comunicación.
            </li>
            <li>
                El Usuario es responsable de la veracidad de la información comunicada, asumiendo las consecuencias de haber actuado de mala fe o enviado información o documentación falsa.
            </li>
            <li>
                El Usuario responde de cualquier daño o perjuicio de cualquier índole que Ballenoil pudiera sufrir, directa o indirectamente, como consecuencia de un uso indebido del producto, un incumplimiento de los términos y condiciones de uso o un incumplimiento
                de la ley vigente.
            </li>
            <li>
                El Usuario se compromete a utilizar el Canal de Denuncias, exclusivamente, en los términos previstos en estos Términos de Uso y Funcionamiento, y a no actuar, ni permitir que terceras personas actúen en su nombre, para:
                <ul>
                    <li>
                        hacer y distribuir copias del Canal de Denuncias;
                    </li>
                    <li>
                        hacer y distribuir descargas ilegales del Canal;
                    </li>
                    <li>
                        intentar copiar, reproducir, alterar o modificar, hacer ingeniería inversa, desarmar, descompilar trasferir, intercambiar, o traducir el Canal;
                    </li>
                    <li>
                        y, en general, a no crear ninguna clase de proyecto derivado del Canal de Denuncias.
                    </li>
                </ul>
            </li>
        </ul>
        <hr>
        <strong>Objeto del Canal de Denuncias</strong>
        <br><br>
        <p>
            Ballenoil ha implementado y puesto en marcha el presente Canal de Denuncias, a través de plataforma digital online, que tiene por objeto constituir un mecanismo eficaz para fortalecer nuestro compromiso de comportamiento ético y responsable en las actividades
            y operaciones de esta entidad, a través de la colaboración de todos los empleados, colegiados, colaboradores y terceros que tengas relación con Ballenoil.
        </p>
        <p>
            Este canal tiene por objeto que cualquier persona relacionada con la actividad de Ballenoil, y que pueda tener la condición de informante conforme al Reglamente del Sistema Interno de Información de Ballenoil, S.A., pueda informar, comunicar o denunciar
            hechos, irregularidades, riesgos, acciones u omisiones incluidos en el ámbito material del Canal.
        </p>
        <hr>
        <strong>¿Quiénes pueden informar a través del Canal de Denuncias de Ballenoil, S.A.?</strong>
        <br><br>
        <p>
            Todos los miembros del órgano de gobierno o directivos de Ballenoil, empleados o trabajadores por cuenta ajena de Ballenoil, S.A., personal autónomo, colaboradores, proveedores y terceros que se relacionen con Ballenoil; incluida cualquier persona que
            trabaje para o bajo la supervisión y dirección de contratistas, subcontratistas y proveedores.
        </p>
        <p>
            Igualmente, todas las personas con relación laboral, de arrendamiento de servicios, estatutaria o similar ya finalizada, voluntarios, becarios, trabajadores en periodo de formación con independencia de que perciban o no una remuneración, así como aquellos
            cuya relación laboral todavía no haya comenzado, en los casos que la información de la posible infracción se haya obtenido durante el proceso de selección o de negociación precontractual.
        </p>
        <hr>
        <strong>¿Quiénes pueden ser denunciados a través del Canal de Denuncias?</strong>
        <br><br>
        <p>
            Cualquier miembro del órgano de gobierno o directivo de Ballenoil, empleados o trabajadores por cuenta ajena de Ballenoil, personal autónomo, colaboradores, proveedores y terceros que se relacionen con Ballenoil, S.A.; incluida cualquier persona que trabaje
            para o bajo la supervisión y dirección de contratistas, subcontratistas y proveedores, en relación con las infracciones objeto del Sistema Interno de Información (SII).
        </p>
        <hr>
        <strong>¿Qué se puede comunicar o denunciar a través del Canal?</strong>
        <br><br>
        <p>
            A través del Sistema Interno de Información y de su Canal de Denuncias, las personas incluidas en el ámbito subjetivo del SII de acuerdo con lo previsto en este Reglamento, podrán comunicar los siguientes comportamientos:
        </p>
        <ul>
            <li type="a">
                Cualesquiera acciones u omisiones que puedan constituir infracciones del Derecho de la Unión, siempre que se cumplan las condiciones que se especifican en el Anexo I del Reglamento del SII de Ballenoil.
            </li>
            <li type="a">
                Acciones u omisiones que puedan ser constitutivas de infracción penal o administrativa grave o muy grave. De forma no limitativa ni excluyente, estas infracciones pueden versar sobre las siguientes materias:
            </li>
            <li>
                Fraude.
            </li>
            <li>
                Estafas.
            </li>
            <li>
                Trato degradante, discriminación y acoso laboral (moral y sexual).
            </li>
            <li>
                Corrupción y soborno.
            </li>
            <li>
                Blanqueo de capitales.
            </li>
            <li>
                Delitos e infracciones contra la Seguridad Social y la Hacienda Pública.
            </li>
            <li>
                Evasión y fraude fiscal.
            </li>
            <li>
                Infracciones del derecho laboral en materia de seguridad y salud en el trabajo.
            </li>
            <li>
                Protección de los datos personales y la intimidad.
            </li>
            <li>
                Seguridad de las redes y sistemas de información.
            </li>
            <li>
                Infracciones o delitos contra el medio ambiente.
            </li>
            <li>
                Salud pública.
            </li>
            <li>
                Cualquier otra conducta que implique la comisión de un delito.
            </li>
            <li>
                Cualquier otra conducta que implique la comisión de una infracción administrativa grave o muy grave.
            </li>
            <li>
                Otras.
            </li>
        </ul>
        <hr>
        <strong>Acceso al Canal y Forma de presentación de las comunicaciones </strong>
        <br><br>
        <ul>

            <li>
                <strong>Acceso al Canal:</strong> A través de la página web de Ballenoil podrá accederse al Canal de Denuncias, a través del correspondiente enlace, el cual redirigirá al Usuario a la plataforma digital que desempeña la función de Canal
                de Denuncias.
            </li>
            <li>
                <strong>Campos obligatorios: </strong> Para poder formular la comunicación del Usuario deberá cumplimentar una serie de datos y paramentos relativos a la misma.
                <br> Si el Usuario no rellena todos paramentos requeridos la aplicación informática no le permitirá efectuar la comunicación.
            </li>
            <li>
                <strong>Identificación: </strong> En garantía del anonimato y protección del informante, el Usuario podrá elegir entre enviar la comunicación indicando sus datos de identificación y contacto, o de forma anónima.
            </li>
            <li>
                <strong>Recepción de las comunicaciones: </strong> Las comunicaciones que se presentan a través de este Canal serán recibidas por el Responsable del Sistema de Ballenoil, o, en caso de gestión externa, por este gestor externo.
                <br> A cada comunicación registrada en el Canal de Denuncias, se le asigna, de forma aleatoria y automática por el sistema digital, un identificador, que permitirá al informante acceder al estado del expediente, a través del mismo sistema,
                y sin necesidad de identificarse personalmente.
            </li>
            <li>
                <strong>Admisión a trámite: </strong> El Responsable del Sistema o el gestor externo, en su caso, llevará a cabo un primer análisis preliminar, en el que se valorarán los indicios de la infracción denunciada y, en consecuencia, decidirá
                sobre la conveniencia de iniciar o no una investigación, pudiendo:
                <ul>
                    <li type="a">
                        Inadmitir la comunicación, en alguno de los siguientes casos:
                        <ul>
                            <li>
                                Los hechos comunicados carezcan de toda verosimilitud.
                            </li>
                            <li>
                                Los hechos comunicados no sean constitutivos de una infracción incluida en el ámbito material u objetivo del Sistema Interno de Información.
                            </li>
                            <li>
                                La comunicación carezca manifiestamente de todo fundamento o indicio, o existan indicios razonables de que la comunicación ha sido formulada con manifiesta mala fe por parte de informante.
                            </li>
                            <li>
                                La comunicación verse sobre hechos anteriormente denunciados y los mismos ya hayan sido objeto de investigación, o la comunicación sea una mera reproducción de otra comunicación anterior inadmitida.
                            </li>
                        </ul>
                    </li>
                    <li type="a">
                        Admitir a trámite la comunicación, comenzando la instrucción del expediente.
                    </li>
                </ul>
            </li>
            <li>
                <strong>
                Instrucción del expediente: 
            </strong> Cualquier persona que haya sido objeto de denuncia será informada con carácter inmediato sobre:
                <ul>
                    <li>
                        la recepción de la denuncia;
                    </li>
                    <li>
                        los hechos comunicados
                    </li>
                    <li>
                        los departamentos y terceros que podrán ser informados de la denuncia;
                    </li>
                    <li>
                        el derecho a formular alegaciones por escrito; y
                    </li>
                    <li>
                        el tratamiento de sus datos personales y cómo ejercitar sus derechos de acceso, rectificación, cancelación y oposición, de conformidad con la normativa de protección de datos.
                    </li>
                </ul>
                <br> En ningún caso se comunicará al denunciado/ afectado la identidad de la persona comunicante, ni se le dará acceso a la comunicación presentado ni al código de identificación de la misma.
                <br> No obstante lo anterior, si a juicio del Responsable del Sistema, por iniciativo propia o a propuesta del gestor externo, existe riesgo de que la notificación al denunciado comprometa la investigación, dicha comunicación podrá aplazarse
                hasta que dicho riesgo desaparezca y, como máximo, hasta el trámite de alegaciones. En todo caso, el plazo para informar al denunciado no excederá de dos (2) meses desde que se haya recibido la denuncia; ello sin perjuicio de que, a la
                vista de las circunstancias concretas, la ley establezca la obligación de observar un plazo superior.
                <br> La instrucción comprenderá todas aquellas actuaciones necesarias para conocer los hechos comunicados, de acuerdo con lo previsto en el Reglamento del SII, dándose en todo caso audiencia a la persona afectada.
            </li>
            <li>
                <strong>Resolución del expediente: </strong> Concluidas las actuaciones de investigación, el Responsable del Sistema, con la asistencia del gestor externo en su caso, emitirá un informe de resolución (no público), que contendrá los hechos
                comunicados, la clasificación de la comunicación a efectos de prioridad, las actuaciones de instrucción y su valoración y la decisión adoptada entre las siguiente:
                <ul>
                    <li>
                        <strong>Archivo del expediente, </strong>en aquello casos en que se concluya que los hechos comunicados no constituyen una infracción.
                    </li>
                    <li>
                        <strong>Aplicación del Régimen Sancionador de Ballenoil u otro régimen de actuación aplicable </strong> a la persona denunciada. En estos casos, el Responsable del Sistema dará traslado al órgano o persona competente en materia
                        de recursos humanos de Ballenoil, o al órgano interno de Ballenoil que fuera competente para la adopción de las correspondientes medidas.
                    </li>
                    <li>
                        <strong>Comunicación a las autoridades policiales, judiciales o administrativas competentes. </strong>Si el delito afectase a los intereses financieros de la Unión, Ballenoil remitirá el expediente a la Fiscalía Europea.
                    </li>
                </ul>
                <br> El <strong>plazo máximo para la emisión por el Responsable del Sistema del informe de resolución es de tres (3) meses</strong> desde la fecha de presentación de la comunicación. Sin perjuicio de ello, de forma excepcional y motivada,
                en aquellos casos en que la conducta denunciada fuera aparentemente constituyente de un delito, la investigación del expediente podrá demorarse hasta seis (6) meses, siempre de forma motivada. Esta motivación deberá comunicarse al informante
                a través del Canal de Denuncias, siendo accesible mediante el código de identificación de la comunicación.
            </li>

        </ul>
        <hr>
        <strong>Principios básicos del Sistema Interno de Información</strong>
        <br><br>
        <p>
            Ballenoil ha puesto en funcionamiento el Sistema Interno de Información asegurando las siguientes garantías fundamentales:
        </p>
        <ul>
            <li>
                <strong>Confidencialidad: </strong> La identidad de la persona que realice la comunicación tendrá la consideración de información confidencial y no podrá ser comunicada ni revelada sin su consentimiento.
                <br> Igualmente, el acceso a la información que se comunique a través del Canal de Denuncias se ha restringido a las personas con potestad de gestión, según las funciones que tienen asignadas de acuerdo con el presente Reglamento, quedando
                expresamente prohibida la divulgación de cualquier tipo de información sobre las denuncias.
                <br> No obstante, los datos de las personas que realicen la comunicación, en caso de haber sido facilitados, así como de las personas a que se refiera la comunicación, podrán ser facilitados tanto a las autoridades administrativas como
                a las judiciales siempre que fueran requeridos como consecuencia de cualquier procedimiento judicial derivado del objeto de la denuncia.
                <br> Dicha cesión de los datos a las autoridades administrativas o judiciales se realizará siempre dando pleno cumplimiento a la legislación vigente sobre protección de datos de carácter personal.
            </li>
            <li>
                <strong>Anonimato y no rastreabilidad: </strong> Las comunicaciones a través del Canal de Denuncias podrán realizarse de forma anónima o con identificación del denunciante, a elección del mismo. Queda prohibido el rastreo y trazabilidad
                de las denuncias anónimas.
                <br> En caso de denuncias con identificación del denunciante, la misma no es comunicada, en ningún caso, a la persona o personas denunciadas, siendo conocida únicamente por las personas encargadas de la gestión del Sistema Interno de Información,
                dentro de su respectivo ámbito de funciones. La revelación de la identidad del denunciante requiere, en todo caso, consentimiento previo del denunciante.
                <br> Para asegurar estos extremos, el seguimiento de las comunicaciones presentadas se realiza a través de un código alfanumérico aleatorio, y en la configuración y diseño del Canal de Denuncias se han implementado medidas de seguridad
                informática dirigidas tanto al borrado automático de accesos como a la evitación de ataques informáticos que pudieran suponer una filtración de datos.
            </li>
            <li>
                <strong>Protección de Datos Personales: </strong> Los tratamientos de datos personales que deriven de la aplicación de la Ley 2/2023, se regirán por lo dispuesto en el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27
                de abril de 2016; en la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales; en la Ley Orgánica 7/2021, de 26 de mayo, de protección de datos personales tratados para fines de
                prevención, detección, investigación y enjuiciamiento de infracciones penales y de ejecución de sanciones penales, y en el presente Reglamento.
            </li>
            <li>
                <strong>Accesibilidad: </strong> El canal de comunicación, público y de fácil acceso, ha sido configurado de forma clara a través de la página web corporativa de Ballenoil.
            </li>
            <li>
                <strong>Conflictos de Interés e Independencia: </strong> En todo caso, el Sistema Interno de Información se gestionará de manera objetiva, en base a criterios de imparcialidad y respeto al presente Reglamento. Asimismo, el protocolo de
                gestión de comunicaciones recoge medidas para gestionar y controlar los posibles conflictos de intereses.
                <br> Con el objetivo de reforzar esta independencia, objetividad y respeto a las garantías recogidas en este Reglamento, el proceso de gestión de las denuncias se ha confiado a un experto externo.
            </li>
            <li>
                <strong>Buena fe: </strong>La protección del denunciante que proporciona el Sistema Interno de Información requiere que la comunicación se formule de buena fe por el denunciante.
                <br> Se presumirá que el denunciante actúa de buena fe, siempre que la comunicación se realice conforme a lo dispuesto en este Reglamento y esté basada en hechos o indicios de que los razonablemente puede desprenderse la existencia del
                comportamiento denunciado.
                <br> Se considerará que el denunciante actúa de mala fe cuando sea consciente de la falsedad de los hechos, o actué con manifiesto desprecio a la verdad o con ánimo de venganza, de acosar moralmente, de causar un perjuicio laboral o profesional,
                o de lesionar el honor de la persona denunciada o de un tercero.
            </li>
            <li>
                <strong>Protección del denunciante y Prohibición de represalias: </strong> Los denunciantes que, de buena fe, comuniquen la presunta comisión de una conducta comprendida en el ámbito de aplicación del Sistema Interno de Información estarán
                protegidos contra cualquier tipo de represalia, discriminación y penalización por motivo de las denuncias realizadas; si bien, la presente prohibición de represalias no impedirá la adopción de las medidas disciplinarias que procedan cuando
                la investigación interna determine que la comunicación es falsa y que la persona que la ha realizado ha actuado con mala fe.
            </li>
            <li>
                <strong>Derechos del denunciado y de las personas afectadas: </strong> De conformidad con lo previsto en este Reglamento, las personas denunciadas tienen derecho a la presunción de inocencia, así como a ser informados de la denuncia formulada.
            </li>
            <li>
                <strong>Registro: </strong>Se elabora un registro automatizado, a través de la propia herramienta del Canal de Denuncias, de las comunicaciones recibidas, como garantía de su tratamiento, gestión y no alteración de forma independiente
                y sin conflictos de intereses.
            </li>
        </ul>
        <p>
            Para más información sobre el Sistema Interno de Información de Ballenoil puede consultar el Reglamento del Sistema, accesible en la página web de Ballenoil: <a href="https://www.ballenoil.es/">https://www.ballenoil.es/</a>.
        </p>
    </div>
    <div style="height: 150px;"></div>
</div>